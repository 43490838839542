import React from 'react'
import MeetPage from '@/pages/followup/meetPage'

const Meet: React.FC = () => (
  <MeetPage
    backUrl="/our-platform/followup"
    forwardsUrl="/our-platform/followup"
  />
)

export default Meet
